import { Link, useNavigate } from "react-router-dom";
import "./topbar.css";
import { Search, Person, Chat, Notifications } from "@material-ui/icons";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext"; 
export default function Topbar() {
  const { currentUser, logout } = useAuth(); 
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate(); 
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  // Handle logout
  const handleLogout = async () => {
    try {
      await logout(); 
      navigate("/"); 
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <div className="topbarContainer">
      <div className="topbarLeft">
        <Link to="/" className="logo-a">
        <img src="/assets/logo.png" className="logo" alt="" />
          {/* <span className="logo"></span> */}
        </Link>
      </div>
      <div className="topbarCenter">
        <div className="searchbar">
          <Search className="searchIcon" />
          <input
            placeholder="Search for friend, post or video"
            className="searchInput"
          />
        </div>
      </div>
      <div className="topbarRight">
        <div className="topbarLinks">
          <span className="topbarLink">Homepage</span>
          <span className="topbarLink">Timeline</span>
          <div className="dropdownContainer" onClick={toggleDropdown}>
            <span className="topbarLink">More</span>
            {isDropdownOpen && (
              <div className="dropdownMenu">
                {/* Display SignUp and Login if the user is not logged in */}
                {!currentUser ? (
                  <>
                    <Link to="/register" className="dropdownItem">
                      SignUp
                    </Link>
                    <Link to="/login" className="dropdownItem">
                      Login
                    </Link>
                  </>
                ) : (
                  // Display Logout if the user is logged in
                  <span className="dropdownItem" onClick={handleLogout}>
                    Logout
                  </span>
                )}
                <Link to="/help" className="dropdownItem">
                  Help
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="topbarIcons">
          <div className="topbarIconItem">
            <Person />
            <span className="topbarIconBadge">1</span>
          </div>
          <div className="topbarIconItem">
            <Chat />
            <span className="topbarIconBadge">2</span>
          </div>
          <div className="topbarIconItem">
            <Notifications />
            <span className="topbarIconBadge">1</span>
          </div>
        </div>
        {currentUser && (
          <Link to={`/profile/${currentUser.displayName}`}>
            <img
              src={currentUser.photoURL || "/assets/person/1.jpeg"}
              alt=""
              className="topbarImg"
            />
          </Link>
        )}
      </div>
    </div>
  );
}
